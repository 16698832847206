
.header{
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    .header__top{
        background-color: #242222;
        box-shadow: 5px 0px 10px #303235;
        .header__top__container{
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            .header__top__logo{
                width: 70px;
                img{
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            .header__top__title{
                font-size: 30px;
                font-weight: 700;
                font-family:Verdana, Geneva, Tahoma, sans-serif;
                letter-spacing: 2px;
            }
            .header__top__city{
                display: flex;
                font-family:Verdana, Geneva, Tahoma, sans-serif;
                align-items: center;
                justify-content: space-between;
                font-size: 18px;
                img{
                    width: 25px;
                    height: 25px;
                    margin-right: 10px;
                }
                @media (max-width:470px) {
                    display: none;
                }
            }
            .header__top__search{
                width: 420px;
                label{
                    position: relative;
                    width: 320px;
                    margin: 0 auto;
                    input{
                        width: 100%;
                        height: 42px;
                        padding-left: 10px;
                        border: 2px solid #7BA7AB;
                        border-radius: 5px;
                        outline: none;
                        background: #F9F0DA;
                        color: #9E9C9C;
                        font-size: 18px;
                    }
                    button {
                        position: absolute; 
                        top: -12px;
                        right: 0px;
                        width: 42px;
                        height: 42px;
                        border: none;
                        background: #7BA7AB;
                        border-radius: 0 5px 5px 0;
                        cursor: pointer;
                        img{
                            width: 25px;
                            height: 25px;
                        }
                    }
            }
            }
            
        }
    }
    .header_center{
        nav{
            padding: 10px 0;
            width: 100%;
            height: 100%;
            ul{
                // display: grid;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 50px;
                // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
                text-align: center;
                :not(:last-child){
                    border-right: 1px solid #000;
                }
                @media (max-width:1083px) {
                    gap: 0px;
                }
                @media (max-width:750px) {
                    overflow-x: scroll;
                    justify-content: left;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
                li{
                    padding: 0 20px;
                    a{
                        font-size: 20px;
                        color: #303235;
                        font-weight: 600;
                        line-height: 1.3;
                        text-transform: uppercase;
                        text-wrap: nowrap;
                        white-space: nowrap;
                        font-family: Verdana, Geneva, sans-serif;
                        transition: .2s ease;
                        @media (max-width:900px) {
                            font-size: 16px;
                        }
                        &:hover{
                            color: #e7353e;
                        }
                       
                    }
                }
            }
        }
    }
    .header_bottom{
        background-color: #242222;
        box-shadow: 5px 0px 10px #303235;
        .header_bottom_container{
            nav{
            padding: 10px 0;
            width: 100%;
            height: 100%;
            ul{
                // display: grid;
                display: flex;
                align-items: center;
                gap: 50px;
                justify-content: center;
                // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
                text-align: center;
                :not(:last-child){
                    border-right: 1px solid #cbc7c7;
                }
                @media (max-width:1083px) {
                    gap: 0px;
                }
                @media (max-width:750px) {
                    overflow-x: scroll;
                    justify-content: left;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
                li{
                    padding: 0 20px;
                    a{
                        font-size: 20px;
                        color: #fff;
                        // color: #303235;
                        font-weight: 600;
                        line-height: 1.3;
                        text-transform: uppercase;
                        text-wrap: nowrap;
                        font-family: Verdana, Geneva, sans-serif;
                        transition: .2s ease;
                        @media (max-width:900px) {
                            font-size: 16px;
                        }
                        &:hover{
                            color: #e7353e;
                        }
                       
                    }
                }
            }
        }
        }
    }
    
}