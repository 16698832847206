.subPageSwiper__wrapper{
    .subPageSwiper__item{
        height: 400px;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }    
    }
    .swiper-button-next::after, .swiper-button-prev::after {
        color: #232323;
    }
}