
.homeItem__wrapper{
  margin-top: 5px;
}
.subItem__wrapper__container{
  width: 70%;
  margin: 0 auto;
  @media (max-width:810px) {
    width: 90%;
}
}
.subItem__images{
  position: relative;
  display: flex;
  justify-content: center;
  img{
    max-height: 700px;
    object-fit: cover;
    @media (max-width:810px) {
      width: 100%;
      height: 100%;
  }
  }
  h3{
    position: absolute;
    left: 50%;
    bottom: 3%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 45px;
    width: 50%;
    text-align: center;
    font-weight: 700;
    display: none;
  }
}
.subItem__actions{
  display: flex;
  justify-content: center;
  gap: 50px;
  padding: 30px 0;
  @media (max-width:510px) {
    flex-direction: column;
    gap: 10px;
    justify-content: center;
  }
  .subItem__actions__link{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    a{
      color: #000;
      display: flex;
      align-items: center;
      gap: 5px;
      img{
        width: 40px;
        height: 40px;
      }
    }
  }
}
.subItem__info{
  margin-bottom: 50px;
  h2{
    font-size: 38px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 700;
    @media (max-width:500px) {
      font-size: 28px;
    }
  }
  h3{
    font-size: 20px;
    position: relative;
    text-align: center;
    padding-bottom: 40px;
    line-height: 130%;
    &::after{
      content: "";
      background-color: #ee3942;
      width: 100px;
      height: 2px;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }
  }
  p{
    white-space: pre-line;
    margin-top: 40px;
    font-size: 16px;
    font-family: PT Serif,Georgia,Times New Roman,Times,serif;
    line-height: 130%;
  }
}
.subItem__contact{
  margin: 20px 0 40px;
  border-top: 1px solid #787676;
  padding-top: 25px;
  white-space: pre-line;
  .subItem__contact__info{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
    @media (max-width:700px) {
      grid-template-columns: 1fr;
    }
    li{
      display: flex;
      flex-direction: column;
    }
  }
  .subItem__contact__schedule{
    margin-top: 20px;
    h4{
      font-size: 22px;
      font-weight: 700;
    }
    .subItem__contact__schedule__info{
      margin-top: 10px;
      display: flex;
      gap: 20px;
    }
  }
  .subItem__contact__dataPubloscher{
    font-size: 16px;
    margin-top: 15px;
  }
}