
.page_404{
    background-color: #F3F3F3;
    display: flex;
    justify-content: space-around;
    margin-top: -20px;
    padding: 50px 10px;
    @media (max-width:820px) {
        flex-direction: column;
    }
}
.page_404__text{
    width: 320px;
    h2{
        color: #1A2E35;
        font-size: 48px;
        font-weight: 700;
    }
    h3{
        font-size: 28px;
        line-height: 115%;
        color: #1A2E35;
        font-family: "Russo One", sans-serif;
        margin: 5px 0 20px;
    }
    p{
        font-size: 18px;
        line-height: 120%;
    }
}
.button_404{
    margin-top: 50px;
    background-color: #FF4F5B;
    border-radius: 15px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: #F3F3F3;
    gap: 10px;
    padding: 10px 20px;
}
.page_404__images{
    width: 40%;
    @media (max-width:820px) {
        width: 100%;
        padding-top: 20px;
    }
}