


.footer{
    background-color: #111d30;
    background-color: #4b4581;
    width: 100%;
    height: 100%;
    padding: 20px 0px 0;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    box-shadow: 5px 0px 10px #3e3c3c;
}
.footer-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
}
.footer-top-social{
    display: flex;
    align-items: center;
    gap: 10px;
    img{
    width: 50px;
}}
.footer-top-logo{
    width: 100px;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.footer-center{
    margin: 20px 0;
}
.footer-center-top{
    &:not(:last-child){
        margin-bottom: 20px;
    }
    ul{
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width:760px) {
            overflow-x: scroll;
            justify-content: left;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        li{
            padding: 0 20px;
            &:not(:last-child){
                border-right: 1px solid #fff;
            }
            
            a{
                color: #fff;
                font-size: 20px;
                font-weight: 600;
                line-height: 1.3;
                text-transform: uppercase;
                text-wrap: nowrap;
                transition: .2s ease;
                @media (max-width:900px) {
                    font-size: 16px;
                }
                &:hover{
                    color: #e7353e;
                }
            }
        }
    }
}
.footer-contact{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px 20px;
    color: #c7c7c7;
    @media (max-width:520px) {
        flex-direction: column;
        gap: 20px;
        align-items: start;
    }
}
.footer-contact-compani{
    width: 300px;
    @media (max-width:520px) {
        width: 100%;
    }
}
.footer-contact-item{
    display: flex;
    flex-direction: column;
    gap: 10px;
    a{
        color: #c7c7c7;
        // color: #ffffff;
    }
}
.footer__bottom{
    border-top: 1px solid #c7c7c7;
    // background-color: #000;
    // background-color: #687379;
    background-color: #3d386a;

    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    h1{
        color: #ffffff;
    }
}




.footer__top{
    display: flex;
    justify-content: space-between;
}
.footer__link{
    border-right:1px solid #c7c7c7;
    border-left:1px solid #c7c7c7;
    padding: 0 40px;
    margin: 20px 0;
    ul li a{
        color: #fff;
        transition: .2s;
        font-size: 25px;
        line-height: 120%;
        &:hover{
            color: #ff8562;
        }
    }
}
.footer__info{
    margin: 20px 0;
    flex: 1;
    padding-left: 80px;
    ul{
        li a{
        color: #fff;
        transition: .2s;
        font-size: 25px;
        line-height: 120%;
        &:hover{
            color: #ff8562;
        }
    }
}}
.footer__kontakt{
    border-right:1px solid #c7c7c7;
    border-left:1px solid #c7c7c7;
    padding: 0 40px;
    margin: 20px 0;
    a{
        display: block;
        color: #fff;
        transition: .2s;
        font-size: 25px;

        &:hover{
            color: #ff8562;
        }
    }
}
