
// fonts
@import url('https://fonts.googleapis.com/css2?family=Advent+Pro:ital,wght@0,800;1,800&family=Fira+Sans:wght@400;700&family=Oswald&family=Russo+One&display=swap');
// font-family: "Oswald", sans-serif;
//  font-family: "Russo One", sans-serif;



html, body{
	// background-color:#f1f1f1;
	background-color:#ffffff;
	// background-color:#e0ebfc;
	font-size: 16px;
	font-family: PT Serif,Georgia,Times New Roman,Times,serif;
}
.wrapper{
	margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
._container{
	width: 1400px;
	height: 100%;
    padding: 0 10px;
	margin: 0 auto;
    @media (max-width:1400px) {
        width: 100%;
    }
}
//стили для красной кнопки на сайте кнопки 
.button__buy{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    padding: 8px 14px;
    color: #fff;
    border-radius: 1px;
    vertical-align: middle;
    line-height: 1.1;
    margin: 5px 0;
    font-family: PT Sans,Arial,sans-serif;
    font-weight: 700;
    font-size: 14px;
    background: #ee3942;
    z-index: 100;
    transition: .2s;
    &:hover{
        background: #c93138;
    }
    @media (max-width:510px) {
        
    }
}
//стили для фото, затемнение
.schadowImg{
    &::after{
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(0deg, rgba(0,0,0,0.6867997198879552) 1%, rgba(255,255,255,0) 100%);
        z-index: 1;
        border-radius: 10px;
        pointer-events: none;
    }
    h3{
        z-index: 5;
    }
}
//стили для компонентов оболочка
._body__box__shadow{
    position: relative;
    border-radius: 20px;
    box-shadow: 0px 0px 10px #acabab;
    background-color: #f1f1f1;
    padding: 10px;
}

// Общие стили для структуры страниц Театр, кино и т.д.
.build__wrapper{
    margin: 5px -300px 15px 0;
    float: left;
    width: 100%;
    z-index: 5;
    @media (max-width:900px) {
        margin: 5px 0px 15px 0px;
    }
    .build__container{
        background-color: #fff;
        display: flex;
        flex-direction: column;
        gap: 30px;
        margin-right: 315px;
        @media (max-width:900px) {
            margin-right: 0px;
        }
        .build__minGrid{
            .build__minGrid__container{
                margin-top: 60px;
                width: 100%;
                height: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 10px;
                @media (max-width:720px) {
                    grid-template-columns: 300px 300px 300px 300px 300px 300px;
                    grid-template-rows: 1fr;
                    overflow-x: scroll;
                }
                .build__minGrid__item{
                    position: relative;
                    width: 100%;
                    height: 300px;
                    background-position: center;
                    background-repeat: no-repeat;
                    border-radius: 10px;
                    overflow: hidden;
                    transition: .2s;
                    &:hover{
                        filter: brightness(60%);
                    }
                    h3{
                        position: absolute;
                        left: 50%;
                        bottom: 5px;
                        transform: translateX(-50%);
                        font-size: 30px;
                        width: 98%;
                        margin: 0 auto;
                        color: #fff;
                        font-family: "Oswald", sans-serif;
                        line-height: 100%;
                        z-index: 2;
                    }
                }
            }
        }
        .build__content{
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 20px;
            padding: 5px;
            @media (max-width:800px) {
                grid-template-columns: 1fr 1fr;
            }
            @media (max-width:500px) {
                grid-template-columns: 1fr;
            }
            .build__content__item{
                background-color: #f1f1f1;
                border-radius: 10px;
                box-shadow: 0px 0px 10px  #acabab;
                transition: .2s;
                &:hover{
                    filter: brightness(90%);
                }
                img{
                    border-radius: 5px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .build__content__item__images{
                    height: 300px;
                }
                .build__content__item__content{
                    padding: 14px;
                }
                h4{
                    font-size: 20px;
                    color: #2f3235;
                    font-family: "Oswald", sans-serif;
                }
                p{
                    font-family: PT Sans,Arial,sans-serif;
                    font-size: 13px;
                    color: #2f3235;
                    margin-top: 8px;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -moz-box;
                    -moz-box-orient: vertical;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    line-clamp: 3;
                    box-orient: vertical;
                }
                h5,h2,h6{
                    font-size: 14px;
                    line-height: 30px;
                    color: #2f3235;
                }
            }
        }
    }
}
.buildAside__container{
    float: right;
    width: 300px;
    background-color: #fff;
    padding: 5px;
}
// end **************************************************

//start style для всех свейперов которые находятся на главной странице
.swiper__wrapper{
    padding-top: 20px;
    .swiper__item{
        position: relative;
        width: 100%;
        height: 400px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 20px;
        overflow: hidden;
        @media (max-width:900px) {
            height: 300px;
        }
        h3{
            position: absolute;
            left: 50%;
            bottom: 9%;
            width: 100%;
            transform: translate(-50%);
            font-size: 30px;
            color: #ffffff;
            font-family: "Oswald", sans-serif;
            line-height: 100%;
            text-align: center;
        }
    }
    .swiper-pagination-bullet{
        width: 20px;
        height: 20px;
        background-color: #acabab;
        animation: pagination-bullet 1s infinite alternate;
        @keyframes pagination-bullet {
            0%{}
            100%{scale: 0.75;}
        }
    }
    .swiper-pagination-bullet-active-main{
        animation: bullet-active 1s infinite alternate;
        @keyframes pagination-bullet {
            0%{}
            100%{transform: translateY(10px);}
        }
    }
}
.swiper__slide{
    margin: 50px 0 40px;
}
.navigation-btns{
    position: absolute;
    right: 1%;
    top: 0px;
    z-index: 1000;
    display: flex;
    gap: 20px;
}

// end **************************************************







/* Reset and base styles  */
* {
	padding: 0px;
	margin: 0px;
	border: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Links */

a, a:link, a:visited  {
    text-decoration: none;
}

a:hover  {
    text-decoration: none;
}

/* Common */

aside, nav, footer, header, section, main {
	display: block;
}

h1, h2, h3, h4, h5, h6, p {
    font-size: inherit;
	font-weight: inherit;
}

ul, ul li {
	list-style: none;
}

img {
	vertical-align: top;
}

img, svg {
	max-width: 100%;
	height: auto;
}

address {
  font-style: normal;
}

/* Form */

input, textarea, button, select {
	font-family: inherit;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
}

input::-ms-clear {
	display: none;
}

button, input[type="submit"] {
    display: inline-block;
    box-shadow: none;
    background-color: transparent;
    background: none;
    cursor: pointer;
}

input:focus, input:active,
button:focus, button:active {
    outline: none;
}

button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

label {
	cursor: pointer;
}

legend {
	display: block;
}
